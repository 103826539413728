import { Campaign } from '../campaign/campaign';

export class PartnerPageCategory {
  category: PartnerCategory;
  partners: Partner[];
}

export class Partner {
  partnerName: string;
  description: string;
  micrositeSlug?: string;
  order?: number;
  moreCampaigns: boolean;
  campaigns?: PartnerCampaign[];
}

export class PartnerCampaign extends Campaign {
  campaignName: string;
}

export enum PartnerCategory {
  AcceleratorsAndIncubators = 1,
  AngelInvestors = 2,
  IndustryPartners = 3,
  Universities = 4,
  VentureCapitalFirms = 5,
}
