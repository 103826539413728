<article class="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-4 tw-pb-4 tw-pt-8">
  <div class="tw-flex tw-gap-4 md:tw-flex-col md:tw-items-center">
    <img
      [src]="
        'https://stagingecfstore.blob.core.windows.net/adminserverdocuments/partnerships/' +
        partner.micrositeSlug +
        '.png'
      "
      [alt]="partner.partnerName"
      class="tw-h-20 tw-w-20 tw-rounded-md md:tw-h-32 md:tw-w-32"
    />
    <div>
      <h4 class="tw-m-0 tw-font-body tw-text-lg tw-font-semibold md:tw-text-center">
        {{ partner.partnerName }}
      </h4>
      <p class="tw-mb-0 tw-max-w-md tw-break-words tw-text-base md:tw-text-center">
        {{ partner.description }}
      </p>
    </div>
  </div>
  <a
    *ngIf="partner.micrositeSlug"
    class="tw-w-full tw-self-center tw-rounded-md tw-border tw-bg-primary tw-px-4 tw-py-2 tw-text-center tw-text-white hover:tw-bg-opacity-80 hover:tw-text-white md:tw-w-fit"
    [routerLink]="['/partner', partner.micrositeSlug]"
  >
    {{ 'common.explore' | translate }} {{ partner.partnerName }} &#8594;
  </a>
  <div class="tw-my-4 tw-w-2/12 tw-border tw-border-gray-300"></div>
  <section
    *ngIf="partner?.campaigns?.length"
    class="tw-flex tw-max-w-full tw-gap-4 tw-overflow-hidden tw-overflow-x-scroll tw-p-4"
  >
    <ng-container *ngFor="let campaign of partner.campaigns">
      <app-partner-campaign-card [campaign]="campaign" class="tw-w-96" />
    </ng-container>
    <app-empty-partner-campaign-card
      *ngIf="partner.moreCampaigns"
      class="tw-w-96"
      [partnerName]="partner.partnerName"
      [slug]="partner.micrositeSlug"
    />
  </section>
</article>
