<div class="row mb-4" *ngIf="description">
  <div class="col-lg-12 text-justify">{{ description | translate }}</div>
</div>
<ng-container *ngFor="let document of documents; let i = index">
  <div class="row document-row">
    <div
      class="col-lg-6 col-md-5"
      [class.col-lg-8]="!document.attachmentType?.sampleUrl"
      [class.col-md-8]="!document.attachmentType?.sampleUrl"
    >
      <div class="d-flex">
        <div class="document-index">{{ i + 1 }}.</div>
        <div class="document-title">
          <span *ngIf="!!document.attachmentType.isOptional"
            >({{ 'company.optional' | translate }})</span
          >
          {{ document.attachmentType.name }}
        </div>
      </div>
    </div>
    <div
      class="col-lg-2 col-md-3 text-right align-self-center"
      *ngIf="document.attachmentType?.sampleUrl"
    >
      <span
        class="cursor-pointer text-primary view-sample"
        (click)="viewSample(document.attachmentType.sampleUrl)"
      >
        {{ 'signUp.downloadTemplate' | translate }}
      </span>
    </div>
    <div class="col-lg-4 col-md-4" *ngIf="!document.attachmentType?.canHaveMultiple">
      <div class="input-field" *ngIf="!document.isUploading">
        <div
          class="document-upload cursor-pointer"
          agmoUploader
          [accept]="accept"
          (selectFiles)="selectFile($event, document)"
          *ngIf="!document.url"
        >
          <div class="document-text">{{ 'signUp.uploadDocument' | translate }}</div>
          <div class="pitchin-icon pitchin-icon-upload"></div>
        </div>
        <div class="document-upload uploaded" *ngIf="document.url">
          <div class="document-text" (click)="viewSample(document.url)">
            {{ document.fileName }}
          </div>
          <div
            class="pitchin-icon pitchin-icon-delete"
            *ngIf="document.attachmentType?.isOptional || !isHideDelete"
            (click)="removeDocument(document, i)"
          ></div>
        </div>
      </div>
      <agmo-loading *ngIf="document.isUploading"></agmo-loading>
    </div>
    <div class="col-lg-4 col-md-4" *ngIf="document.attachmentType?.canHaveMultiple">
      <div class="document-upload cursor-pointer" (click)="uploadAdditionalDocument(document)">
        <div class="document-text">
          {{ 'company.uploadAdditionalDocuments' | translate }}
        </div>
        <div class="pitchin-icon pitchin-icon-upload"></div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="document.subAttachments && document.subAttachments.length > 0">
    <div
      class="row document-row sub-document-row"
      *ngFor="let subAttachment of document.subAttachments; let x = index"
    >
      <div class="col-lg-8 col-md-8 d-flex">
        <div class="document-index">{{ i + 1 }}.{{ x + 1 }}.</div>
        <div class="document-title">
          {{ subAttachment.attachmentName || '-' }}
        </div>
      </div>
      <div class="col-lg-4 col-md-4">
        <div class="input-field">
          <div class="document-upload uploaded" *ngIf="subAttachment.url">
            <div class="document-text" (click)="viewSample(subAttachment.url)">
              {{ subAttachment.fileName }}
            </div>
            <div
              class="pitchin-icon pitchin-icon-delete"
              (click)="removeDocument(subAttachment, i)"
            ></div>
          </div>
        </div>
        <agmo-loading *ngIf="subAttachment.isUploading"></agmo-loading>
      </div>
    </div>
  </ng-container>
</ng-container>
