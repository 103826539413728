import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CampaignInfo } from '../../../core/models/campaign/campaign-info';
import { CampaignMenus } from '../../../core/services/constant';
import { FloatingMenuItem } from '../../../shared/models/floating-menu/floating-menu-item';
import { CampaignStatus } from '../../../core/enum/campaign-status.enum';
import { Campaign } from '../../../core/models/campaign/campaign';
import { StorageService } from '../../../core/services/storage.service';
import { Role } from '../../../core/enum/role.enum';

@Component({
  selector: 'campaign-info-tab',
  templateUrl: './campaign-info-tab.component.html',
  styleUrls: ['./campaign-info-tab.component.scss'],
})
export class CampaignInfoTabComponent implements OnInit, OnChanges {
  @Input() campaign: Campaign;
  @Input() campaignInfo: CampaignInfo;
  @Input() campaignStatus: CampaignStatus;
  @Output() changeTab: EventEmitter<{ tabIndex: number; event?: PointerEvent }> =
    new EventEmitter();

  isIssuer: boolean = false;
  isLegacy: boolean = false;
  legacyBodyContent: string = '';
  bodyContents: FloatingMenuItem[] = [];

  selected: string = 'summary';
  tableOfContents: FloatingMenuItem[] = [];
  mapTableOfContents: { [key: string]: string } = {};

  public isCollapsed = true;

  constructor(private storageService: StorageService) {}

  ngOnInit() {
    this.isIssuer = this.storageService.role == Role.IssuerRep;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['campaignInfo']) {
      this.isLegacy = this.campaignInfo?.isLegacySystem;

      if (this.campaign?.isPrivate && !this.campaign?.isInvited && !this.campaign?.isCreator) {
        this.bodyContents = CampaignMenus.filter((menu) => menu.route === 'summary');
      }

      if (this.isLegacy) {
        this.initLegacyTableOfContent(this.campaignInfo?.summary);
      } else {
        this.initTableOfContents();
      }
    }
  }

  initLegacyTableOfContent(bodyContent) {
    if (!bodyContent) return;

    const parser = new DOMParser();
    const doc = parser.parseFromString(bodyContent, 'text/html');

    const h5s = Array.from(doc.querySelectorAll('h5'));

    h5s.forEach((h5) => {
      const elementId = this.slugify(h5.textContent);
      const elementValue = this.capitalizeFirstLetter(h5.textContent);

      h5.id = elementId;
      h5.textContent = elementValue;
      h5.classList.add('p-text-title-separater', 'tw-mb-10', 'tw-scroll-m-32', 'lg:tw-scroll-m-28');

      this.tableOfContents.push({
        name: elementValue,
        route: elementId,
      });
      this.mapTableOfContents[elementId] = elementValue;
    });

    const serializer = new XMLSerializer();
    this.legacyBodyContent = serializer.serializeToString(doc);
  }

  initTableOfContents() {
    if (!this.campaignInfo) return;

    CampaignMenus.forEach((menu) => {
      if (this.campaignInfo[menu.route]) {
        this.tableOfContents.push(menu);
        this.mapTableOfContents[menu.route] = menu.name;

        if (
          !this.campaign?.isPrivate ||
          (this.campaign?.isPrivate && this.campaign?.isInvited) ||
          this.campaign.isCreator
        ) {
          this.bodyContents.push(menu);
        }
      }
    });
  }

  handleChangeTab(tabEvent) {
    this.changeTab.emit(tabEvent);
  }

  handleScrollChange(selectedSection) {
    if (selectedSection) this.selected = selectedSection;
  }

  handleMenuClick(sectionId: string) {
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });

      setTimeout(() => {
        this.isCollapsed = true;
      }, 10);
    }
  }

  capitalizeFirstLetter(str: string): string {
    if (!str) return;

    return str.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
  }

  slugify(text: string): string {
    return text
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]|^-+|-+$/g, '')
      .replace(/[\s_-]+/g, '-');
  }
}
