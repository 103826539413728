import { Component, Input } from '@angular/core';
import { FaqAccordion } from 'src/app/core/models/learn/faq-accordion';

@Component({
  selector: 'app-faq-accordion',
  templateUrl: './faq-accordion.component.html',
  styleUrls: ['./faq-accordion.component.scss'],
})
export class FaqAccordionComponent {
  @Input() showTitle: boolean = true;
  @Input() accordionItems: FaqAccordion[] = [
    {
      id: '1',
      question: 'Section 1',
      answer: 'Content of section 1',
      open: false,
    },
  ];

  handleClick(item) {
    const accordionItem = this.accordionItems.find((accordion) => accordion.id === item.id);

    accordionItem.open = !accordionItem.open;
  }
}
