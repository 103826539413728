<div class="tw-relative tw-h-[320px] tw-w-full tw-bg-[#d00000]"></div>

<div class="tw-relative -tw-mt-[220px] tw-h-[220px]">
  <svg
    viewBox="0 0 500 150"
    preserveAspectRatio="none"
    class="tw-min-w-500px tw-absolute tw-h-[220px] tw-w-full"
  >
    <path
      d="M-60.00,181.04 C241.25,18.04 269.37,17.03 558.12,181.04 L500.00,150.00 L0.00,150.00 Z"
      style="stroke: none; fill: #ffffff"
    ></path>
  </svg>
</div>

<div class="container tw-relative -tw-mt-[200px] tw-flex tw-flex-col tw-items-center">
  <img src="/assets/img/banner/ekycSuccess.png" alt="success" class="tw-z-20" />
  <div class="row tw-w-4/5 tw-justify-center tw-py-4">
    <div class="col-lg-9 text-center tw-mb-6">
      <h2 class="text-center tw-mb-8 tw-font-black">{{ 'signUp.eKYCSuccess' | translate }}</h2>
      <p class="text-center success-message tw-mb-8 tw-text-sm">
        {{ 'signUp.ekycSuccessMessage' | translate }}
      </p>
      <p [innerHTML]="'signUp.ekycSuccessTip' | translate" class="tw-mb-8 tw-text-sm"></p>
    </div>
  </div>
  <div class="btn-panel tw-mb-12 tw-flex tw-flex-col">
    <div class="btn btn-green tw-mb-2 tw-w-full" routerLink="/equity">
      {{ 'signUp.browseCampaigns' | translate }}
    </div>
    <div
      routerLink="/profile"
      class="btn btn-primary-border tw-w-full !tw-border-[#006837] !tw-text-[#006837]"
    >
      {{ 'signUp.goToProfile' | translate }}
    </div>
  </div>
</div>
