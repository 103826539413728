<a
  class="tw-relative tw-flex tw-h-64 tw-w-96 tw-min-w-96 tw-flex-col tw-justify-between tw-p-4 tw-shadow-[0px_4px_4px_0px_#00000021] tw-text-black"
  [routerLink]="slug"
>
  <section class="tw-flex tw-h-32 tw-w-full tw-items-start tw-justify-between tw-gap-4">
    <div class="tw-flex tw-h-full tw-flex-1 tw-flex-col tw-gap-4">
      <div class="tw-h-6 tw-w-full tw-rounded-md tw-bg-gray-100"></div>
      <div class="tw-h-4 tw-w-full tw-rounded-md tw-bg-gray-100"></div>
      <section class="tw-flex tw-gap-4">
        <div class="tw-h-5 tw-w-12 tw-rounded-md tw-bg-gray-100"></div>
        <div class="tw-h-5 tw-w-12 tw-rounded-md tw-bg-gray-100"></div>
      </section>
    </div>
    <div class="tw-h-20 tw-w-20 tw-rounded-md tw-bg-gray-100"></div>
  </section>
  <div class="tw-h-6 tw-w-full tw-rounded-md tw-bg-gray-100"></div>
  <section class="tw-grid tw-w-full tw-grid-cols-3 tw-gap-4">
    <section class="tw-col-span-1 tw-flex tw-flex-col tw-gap-2">
      <div class="tw-h-4 tw-w-full tw-rounded-md tw-bg-gray-100"></div>
      <div class="tw-h-2 tw-w-full tw-rounded-md tw-bg-gray-100"></div>
    </section>
    <section class="tw-col-span-1 tw-flex tw-flex-col tw-gap-2">
      <div class="tw-h-4 tw-w-full tw-rounded-md tw-bg-gray-100"></div>
      <div class="tw-h-2 tw-w-full tw-rounded-md tw-bg-gray-100"></div>
    </section>
    <section class="tw-col-span-1 tw-flex tw-flex-col tw-gap-2">
      <div class="tw-h-4 tw-w-full tw-rounded-md tw-bg-gray-100"></div>
      <div class="tw-h-2 tw-w-full tw-rounded-md tw-bg-gray-100"></div>
    </section>
  </section>
  <section
    class="tw-absolute tw-bottom-2/4 tw-right-2/4 tw-flex tw-w-96 tw-translate-x-2/4 tw-translate-y-2/4 tw-flex-col tw-items-center tw-justify-center"
  >
    <p class="tw-m-0">Browse more campaigns backed by</p>
    <p class="tw-m-0 tw-font-semibold tw-text-black tw-underline tw-underline-offset-2">
      {{ partnerName }} &#x2192;
    </p>
  </section>
</a>
