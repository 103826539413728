<div
  class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-cover tw-bg-[90%_75%] tw-bg-no-repeat"
  style="background-image: url(../../../../../assets/img/banner/women-in-tech-banner.png)"
>
  <div
    class="tw-flex tw-w-full tw-max-w-[1280px] tw-flex-col tw-items-center tw-gap-4 tw-px-16 tw-py-10 lg:tw-flex-row lg:tw-items-center lg:tw-justify-center lg:tw-gap-10 lg:tw-py-28"
  >
    <img
      src="./assets/img/hero/women-in-tech-logo.png"
      alt="Women in Tech"
      class="tw-w-80 lg:tw-w-[15rem]"
    />
    <h1
      class="tw-m-0 tw-w-full tw-max-w-80 tw-text-lg tw-font-semibold tw-tracking-wide tw-text-white lg:tw-max-w-[40rem] lg:tw-text-4xl"
    >
      Shaping the future of technology with confidence and excellence.
    </h1>
  </div>
  <div
    class="tw-relative tw-flex tw-w-screen tw-justify-center tw-border-t tw-border-white tw-shadow-[0px_4px_32px_0px_#00000029]"
  >
    <div
      class="tw-absolute tw-left-0 tw-z-0 tw-hidden tw-h-full tw-w-6/12 tw-bg-white lg:tw-flex"
    ></div>
    <div
      class="tw-z-10 tw-flex tw-w-full tw-max-w-[1280px] tw-flex-col tw-border-white lg:tw-flex-row lg:tw-justify-center"
    >
      <section
        class="tw-w-full tw-p-1 lg:tw-flex lg:tw-flex-1 lg:tw-justify-end lg:tw-bg-white lg:tw-py-4"
      >
        <div class="tw-flex tw-flex-col tw-items-center tw-p-8 lg:tw-pl-[15px]">
          <div
            class="tw-max-w-[35rem] tw-text-white md:tw-max-w-[80rem] lg:tw-pl-0 lg:tw-text-[#120E95]"
          >
            <p
              class="tw-text-left tw-text-base tw-font-semibold tw-leading-normal tw-tracking-wide sm:tw-text-sm md:tw-text-lg"
            >
              We are proud to announce a new partnership aimed at empowering and accelerating early
              and mid-stage startups in Malaysia.
            </p>
            <p
              class="tw-text-left tw-text-base tw-font-normal tw-leading-normal sm:tw-text-sm md:tw-text-lg"
            >
              <span class="tw-font-semibold">Women in Tech</span>&#174;, a dynamic and inclusive
              community dedicated to fostering empowerment, diversity, and innovation within the
              technology sector, will now be
              <span class="tw-font-semibold">supporting its startups to raise funds</span> via
              equity crowdfunding on pitchIN. This partnership will provide investors with a unique
              opportunity to <span class="tw-font-semibold">support</span> and
              <span class="tw-font-semibold">invest</span> in innovative and exciting startups in
              the region.
            </p>
          </div>
        </div>
      </section>
      <section
        class="tw-flex tw-flex-col tw-items-center tw-bg-white tw-p-6 tw-text-[#120E95] lg:tw-max-w-[20rem] lg:tw-items-start lg:tw-justify-start lg:tw-bg-transparent lg:tw-py-12 lg:tw-pl-14 lg:tw-text-white"
      >
        <p
          class="tw-mb-1 tw-text-center tw-text-base md:tw-text-lg lg:tw-text-left lg:tw-text-lg lg:tw-font-thin"
        >
          Looking to accelerate your women-led startup's growth?
        </p>
        <p class="tw-text-xl tw-font-semibold tw-tracking-wide lg:tw-text-lg">Discover how.</p>
        <a
          class="tw-flex tw-w-full tw-cursor-pointer tw-justify-center tw-rounded-md tw-border tw-bg-transparent tw-p-4 tw-text-lg tw-font-semibold tw-text-[#120E95] hover:tw-border-[#120E95] hover:tw-bg-white hover:tw-text-[#120E95] lg:tw-mt-4 lg:tw-px-4 lg:tw-py-2 lg:tw-text-white"
          href="https://bit.ly/WIT-Founder"
          target="_blank"
        >
          Book a session with us
        </a>
      </section>
    </div>
  </div>
</div>

<section class="section" *ngIf="totalCampaigns === null">
  <div class="container-fluid">
    <agmo-loading></agmo-loading>
  </div>
</section>

<!-- Section: Coming soon (when no campaign exist) -->
<section
  class="section tw-flex tw-min-h-96 tw-flex-col tw-items-center tw-justify-center"
  *ngIf="totalCampaigns !== null && totalCampaigns === 0"
>
  <h2 class="tw-text-sm tw-font-bold md:tw-text-lg">No available campaigns yet</h2>
  <div class="tw-text-sm md:tw-text-lg">All available campaigns will be shown here</div>
</section>

<!-- Section: Live -->
<section class="section" *ngIf="list[CampaignStatus.Live].total > 0">
  <div class="container-fluid">
    <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#120E95] md:tw-text-3xl">
      {{ 'common.liveOpportunities' | translate }}
    </h2>
    <app-campaign-list
      [campaigns]="list[CampaignStatus.Live].campaigns"
      [params]="list[CampaignStatus.Live].params"
      [total]="list[CampaignStatus.Live].total"
      [isLoading]="list[CampaignStatus.Live].isLoading"
      [noRecordText]="'common.comingSoon'"
      (getCampaignList)="getList($event, CampaignStatus.Live)"
    ></app-campaign-list>
  </div>
</section>

<!-- Section: Pre-Live -->
<ng-container *ngIf="list[CampaignStatus.PreLive].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#120E95] md:tw-text-3xl">
        {{ 'common.preLiveCampaigns' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.PreLive].campaigns"
        [params]="list[CampaignStatus.PreLive].params"
        [total]="list[CampaignStatus.PreLive].total"
        [isLoading]="list[CampaignStatus.PreLive].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.PreLive)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<!-- Section: Success -->
<ng-container *ngIf="list[CampaignStatus.Success].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#120E95] md:tw-text-3xl">
        {{ 'campaign.SuccessFundedTitle' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.Success].campaigns"
        [params]="list[CampaignStatus.Success].params"
        [total]="list[CampaignStatus.Success].total"
        [isLoading]="list[CampaignStatus.Success].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.Success)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<section
  class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-bg-[#030039] tw-p-8 md:tw-gap-8 md:tw-p-16"
>
  <h3 class="tw-text-xl tw-font-bold tw-tracking-wide tw-text-[#01EFCD] md:tw-text-4xl">
    About Women in Tech
  </h3>
  <div class="tw-flex tw-max-w-[35rem] tw-flex-col md:tw-max-w-4xl">
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      Women in Tech® is an international non-profit organization dedicated to closing the gender
      gap and helping women embrace technology.
      <span class="tw-font-semibold">
        (<a
          class="tw-text-white hover:tw-text-[#ffffffaa]"
          href="https://women-in-tech.org/"
          target="_blank"
          >https://women-in-tech.org/</a
        >)
      </span>
    </p>
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      The organization promotes the empowerment of girls and women worldwide, with a particular
      focus on Sustainable Development Goal 5b: harnessing technologies.
    </p>
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      Women in Tech&#174; is on a mission to empower 5 million girls and women by 2030.
    </p>
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      With its Head Office in Paris, Women in Tech® is a Global Movement with chapters across six
      continents, impacting over 300,000 individuals and continually growing. This includes regions
      in APAC, with existing chapters in Malaysia, India, Thailand, Japan, Sri Lanka, the
      Philippines, Australia, Vietnam, Singapore, and new chapters opening soon in New Zealand,
      Indonesia, and other locations.
    </p>
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      The aim of Women in Tech® is to educate, equip, and empower women and girls with the
      necessary skills, confidence, and opportunities to succeed in STEAM career fields. It is the
      only organization for women in tech that offers programs for every stage of a woman’s career
      lifecycle—from the classroom to the boardroom.
    </p>
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      Women in Tech&#174; focuses on four primary areas that call for action: Education, Business,
      Digital Inclusion, and Advocacy. The organization creates impact through action, building
      skills and confidence to set women up for success.
    </p>
  </div>

  <a
    href="https://women-in-tech.org/my/"
    target="_blank"
    class="tw-rounded-full tw-bg-[#E8533B] tw-px-6 tw-py-4 tw-text-base tw-font-semibold tw-text-white md:tw-text-lg"
    >More on Women in Tech</a
  >
</section>
