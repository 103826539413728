import { Injectable } from '@angular/core';
import { Menu } from '../models/menu/menu';
import { Role } from '../enum/role.enum';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor() {}

  menu: Menu = {
    mainMenu: [
      {
        name: 'common.invest',
        url: '/equity',
        submenuTitle: 'common.investIn',
        submenus: [
          {
            name: 'menu.ecf',
            icon: 'pitchin-icon-invest-ecf',
            description: 'menu.ecfDescription',
            url: '/equity',
          },
          {
            name: 'menu.ieo',
            icon: 'pitchin-icon-invest-ieo',
            description: 'menu.ieoDescription',
            isExternal: true,
            url: environment.ieoBaseUrl,
          },
        ],
        footermenus: [
          {
            name: 'menu.successFundedCampaign',
            url: '/equity/success',
          },
        ],
      },
      {
        name: 'common.trade',
        url: '/pstx',
        submenuTitle: 'common.trade',
        show: () => {
          return environment.flagToShowPstx;
        },
        submenus: [
          {
            name: 'menu.pstx',
            icon: 'pitchin-icon-invest-pstx',
            description: 'menu.pstxDescription',
            url: '/pstx',
          },
        ],
        footermenus: [
          {
            name: 'menu.howPstxWorks',
            url: '/pstx/how-it-works',
          },
        ],
      },
      {
        name: 'raise.raise',
        url: '/raise',
        icon: 'pitchin-icon-raise',
      },
      {
        name: 'common.learn',
        url: '/learn',
        submenuTitle: 'common.learn',
        submenus: [
          {
            name: 'menu.learningPortal',
            icon: 'pitchin-icon-learn-learning-portal',
            description: 'menu.learningPortalDescription',
            url: '/learn',
          },
          {
            name: 'menu.faq',
            icon: 'pitchin-icon-learn-faq',
            description: 'menu.faqDescription',
            isExternal: true,
            url: 'https://support.pitchin.my/hc/en-us',
            isOpenNewTab: true,
          },
          {
            name: 'menu.academy',
            icon: 'pitchin-icon-learn-academy',
            description: 'menu.academyDescription',
            isExternal: true,
            url: 'https://academy.pitchin.my',
            isOpenNewTab: true,
          },
        ],
        footermenus: [
          {
            name: 'menu.aboutPitchIN',
            url: '/about-us',
          },
        ],
      },
      {
        name: 'partnership.partner',
        url: '/partner',
      },
    ],
    tabMenu: [
      {
        name: 'settings.pstxWallet',
        cssClass: 'underline-ieo-wallet',
        route: ['/pstx', 'account'],
        show: (role: Role) => {
          return (
            [Role.CompanyRep, Role.IndividualInvestor].includes(role) && environment.flagToShowPstx
          );
        },
      },
      {
        name: 'settings.ieoTokenWallet',
        cssClass: 'underline-ieo-wallet',
        url: environment.ieoBaseUrl + '/dashboard/history',
        isExternal: true,
        show: (role: Role) => {
          return false; //PLT-327 Hide this option - [Role.CompanyRep, Role.IndividualInvestor].includes(role);
        },
      },
      {
        name: 'common.profileSettings',
        cssClass: 'underline-profile',
        route: ['/profile', 'settings'],
      },
    ],
    settingsMenu: [
      {
        name: 'settings.profileDetails',
        route: ['/profile', 'settings'],
      },
      {
        name: 'settings.businessDetails',
        route: ['/profile', 'settings', 'business-details'],
        show: (role: Role) => {
          return [Role.CompanyRep, Role.IssuerRep].includes(role);
        },
      },
      {
        name: 'settings.investorType',
        route: ['/profile', 'settings', 'investment'],
        show: (role: Role) => {
          return [Role.CompanyRep, Role.IndividualInvestor].includes(role);
        },
      },
      {
        name: 'settings.bankDetails',
        route: ['/profile', 'settings', 'payment-methods'],
        show: (role: Role) => {
          return [Role.CompanyRep, Role.IndividualInvestor].includes(role);
        },
      },
      {
        name: 'settings.favouriteSectors',
        route: ['/profile', 'settings', 'favourite-sectors'],
        show: (role: Role) => {
          return [Role.CompanyRep, Role.IndividualInvestor].includes(role);
        },
      },
      {
        name: 'settings.security',
        route: ['/profile', 'settings', 'security'],
      },
      {
        name: 'settings.notificationSettings',
        route: ['/profile', 'settings', 'notification'],
      },
    ],
    managementMenu: [
      {
        title: 'common.ecf',
        secondTitle: 'footer.equityCrowdfunding',
        cssClass: 'main-title-ecf',
        show: (role: Role) => {
          return role == Role.IssuerRep;
        },
        submenus: [
          {
            title: 'Applications',
            route: ['/raise/my-application'],
            show: (role: Role) => {
              return role == Role.IssuerRep;
            },
          },
          {
            title: 'Campaigns',
            route: ['/my-campaign'],
            show: (role: Role) => {
              return role == Role.IssuerRep;
            },
          },
        ],
      },
      // {
      //     title: 'settings.manage',
      //     cssClass: 'title-dashboard-submenu',
      //     show: (role: Role) => {
      //         return [Role.CompanyRep, Role.IndividualInvestor].includes(role);
      //     },
      //     submenus: [
      //         {
      //             title: 'settings.pledges',
      //             show: (role: Role) => {
      //                 return [Role.CompanyRep, Role.IndividualInvestor].includes(role);
      //             },
      //             submenus: [
      //                 {
      //                     title: 'settings.ecf',
      //                     route: ['/dashboard/pledges'],
      //                     show: (role: Role) => {
      //                         return [Role.CompanyRep, Role.IndividualInvestor].includes(role);
      //                     }
      //                 },
      //                 {
      //                     title: 'settings.tcf',
      //                     url: environment.ieoBaseUrl + '/dashboard/pledges',
      //                     isExternal: true,
      //                     show: (role: Role) => {
      //                         return [Role.CompanyRep, Role.IndividualInvestor].includes(role);
      //                     }
      //                 }
      //             ]
      //         },
      //         // {
      //         //     title: 'settings.ecf',
      //         //     route: ['/dashboard/pledges'],
      //         //     show: (role: Role) => {
      //         //         return [Role.CompanyRep, Role.IndividualInvestor].includes(role);
      //         //     }
      //         // },
      //         // {
      //         //     title: 'settings.tcf',
      //         //     url: environment.ieoBaseUrl + '/dashboard/pledges',
      //         //     isExternal: true,
      //         //     show: (role: Role) => {
      //         //         return [Role.CompanyRep, Role.IndividualInvestor].includes(role);
      //         //     }
      //         // },
      //         {
      //             title: 'settings.orders',
      //             show: (role: Role) => {
      //                 return [Role.CompanyRep, Role.IndividualInvestor].includes(role) && environment.flagToShowPstx;
      //             },
      //             submenus: [
      //                 {
      //                     title: 'settings.secondaryTradingMarket(PSTX)',
      //                     route: ['/dashboard/orders'],
      //                     show: (role: Role) => {
      //                         return [Role.CompanyRep, Role.IndividualInvestor].includes(role) && environment.flagToShowPstx;
      //                     }
      //                 },
      //             ]
      //         },
      //     ]
      // },
      // {
      //     title: 'settings.pstx',
      //     secondTitle: 'settings.secondaryTradingMarket',
      //     cssClass: 'main-title-pstx',
      //     show: (role: Role) => {
      //         return environment.flagToShowPstx && role !== Role.IssuerRep;
      //     },
      //     submenus: [
      //         {
      //             title: 'settings.orderList',
      //             route: ['/pstx/order-list'],
      //             show: (role: Role) => {
      //                 return [Role.CompanyRep, Role.IndividualInvestor].includes(role);
      //             }
      //         },
      //         {
      //             title: 'settings.watchList',
      //             route: ['/pstx/watch-list'],
      //             show: (role: Role) => {
      //                 return [Role.CompanyRep, Role.IndividualInvestor].includes(role);
      //             }
      //         }
      //     ]
      // },
      {
        title: 'common.general',
        secondTitle: 'common.postFundraising',
        cssClass: 'main-title-pstx',
        show: (role: Role) => {
          return environment.flagToShowPstx && role === Role.IssuerRep;
        },
        submenus: [
          {
            title: 'raise.shareholdersDashboard',
            route: ['/postfundraising/shareholders'],
            show: (role: Role) => {
              return role == Role.IssuerRep;
            },
          },
        ],
      },
      {
        title: 'settings.ieo',
        secondTitle: 'settings.initialExchangeOffering',
        cssClass: 'main-title-ieo',
        submenus: [
          {
            title: 'Applications',
            url: environment.ieoBaseUrl + '/issuer/applications',
            isExternal: true,
          },
          {
            title: 'Campaigns',
            url: environment.ieoBaseUrl + '/issuer/campaigns',
            isExternal: true,
          },
        ],
        show: (role: Role) => {
          return role == Role.IssuerRep;
        },
      },
    ],
    investorDashboardMenu: [
      {
        name: 'dashboard.equity',
        nameForMobile: 'common.equity',
        icon: 'dashboard',
        submenus: [
          {
            name: 'dashboard.portfolio',
            url: '/dashboard/portfolio',
            icon: 'portfolio',
          },
          {
            name: 'dashboard.ecfInvestments',
            url: '/dashboard/pledges',
            icon: 'pledges',
          },
          {
            name: 'dashboard.pstxOrders',
            url: '/dashboard/orders',
            icon: 'invest',
            show: (role: Role) => {
              return environment.flagToShowPstx;
            },
          },
          {
            name: 'dashboard.pstxAccount',
            url: '/pstx/account',
            icon: 'wallet',
          },
          {
            name: 'dashboard.savedWatchlist',
            url: '/dashboard/saved-watchlist',
            icon: 'saved',
          },
          {
            name: 'dashboard.documents',
            url: '/dashboard/statements-reports',
            icon: 'document',
            show: (role: Role) => {
              return environment.flagToShowStatementsReports;
            },
          },
        ],
      },
      {
        name: 'dashboard.token',
        icon: 'dashboard',
        submenus: [
          {
            name: 'dashboard.portfolio',
            url: environment.ieoBaseUrl + '/dashboard/portfolio',
            icon: 'portfolio',
            isExternal: true,
          },
          {
            name: 'dashboard.history',
            url: environment.ieoBaseUrl + '/dashboard/history',
            icon: 'history',
            isExternal: true,
          },
          {
            name: 'dashboard.tcfInvestments',
            url: environment.ieoBaseUrl + '/dashboard/pledges',
            icon: 'pledges',
            isExternal: true,
          },
          {
            name: 'dashboard.tcfWallet',
            url: environment.ieoBaseUrl + '/dashboard/whitelist',
            icon: 'wallet',
            isExternal: true,
            show: (role: Role) => {
              return environment.flagToShowTcfWalletManagement;
            },
          },
        ],
      },
    ],
  };
}
