import { Component, Input } from '@angular/core';
import { Partner } from '../../../core/models/partnership/partner';

@Component({
  selector: 'app-partner-card',
  templateUrl: './partner-card.component.html',
})
export class PartnerCardComponent {
  @Input() partner: Partner;
}
