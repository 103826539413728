<div
  class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-[url(src/assets/img/banner/wtf-banner-mobile.png)] tw-bg-cover tw-bg-no-repeat lg:tw-bg-[url(src/assets/img/banner/wtf-banner-desktop.png)] lg:tw-bg-[40%_30%]"
>
  <div class="lg:tw-flex lg:tw-w-full lg:tw-max-w-[1280px] lg:tw-justify-end">
    <div
      class="tw-flex tw-w-full tw-max-w-[1280px] tw-flex-col tw-items-center tw-gap-4 tw-px-16 tw-py-10 lg:tw-w-8/12 lg:tw-flex-col lg:tw-items-start lg:tw-justify-start lg:tw-gap-2 lg:tw-px-0 lg:tw-py-28"
    >
      <img
        src="./assets/img/hero/wtf-logo-mobile.png"
        alt="WatchTower and Friends"
        class="tw-w-80 lg:tw-w-[15rem]"
      />
      <h1
        class="tw-m-0 tw-w-full tw-max-w-80 tw-text-center tw-font-heading tw-text-xl tw-font-bold tw-tracking-wide tw-text-white [text-shadow:_0px_4px_4px_#00000040] lg:tw-max-w-full lg:tw-text-left lg:tw-text-7xl xl:tw-text-8xl"
      >
        We Start Things
      </h1>
    </div>
  </div>
  <div
    class="tw-relative tw-flex tw-w-screen tw-justify-center tw-border-white tw-shadow-[0px_4px_32px_0px_#00000029] lg:tw-border-t"
  >
    <div
      class="tw-absolute tw-left-0 tw-z-0 tw-hidden tw-h-full tw-w-6/12 tw-bg-white lg:tw-flex"
    ></div>
    <div
      class="tw-absolute tw-right-0 tw-z-0 tw-hidden tw-h-full tw-w-6/12 tw-bg-transparent tw-to-[99%] lg:tw-flex lg:tw-bg-transparent"
    ></div>
    <div
      class="tw-z-10 tw-flex tw-w-full tw-max-w-[1280px] tw-flex-col lg:tw-flex-row lg:tw-justify-center"
    >
      <section
        class="tw-w-full tw-p-1 lg:tw-flex lg:tw-flex-1 lg:tw-justify-end lg:tw-bg-white lg:tw-py-4"
      >
        <div class="tw-flex tw-flex-col tw-items-center tw-bg-white tw-p-8 lg:tw-pl-[15px]">
          <div class="tw-max-w-[35rem] tw-text-[#88121E] md:tw-max-w-[80rem] lg:tw-pl-0">
            <p
              class="tw-text-left tw-text-base tw-font-semibold tw-leading-normal tw-tracking-wide sm:tw-text-sm md:tw-text-lg"
            >
              WatchTower and Friends Sdn Bhd operates WTF Accelerator, a company that is pursuing
              its vision of building a startup ecosystem that will raise an ever-growing number of
              successful startups.
            </p>
            <p
              class="tw-text-left tw-text-base tw-font-normal tw-leading-normal sm:tw-text-sm md:tw-text-lg"
            >
              Operating under the tagline “We Start Things,” WTF Accelerator initiates lines of
              action that benefit startups.
            </p>
          </div>
        </div>
      </section>
      <section
        class="tw-flex tw-flex-col tw-items-center tw-bg-white tw-p-6 tw-text-[#88121E] lg:tw-max-w-[20rem] lg:tw-items-start lg:tw-justify-start lg:tw-bg-transparent lg:tw-py-12 lg:tw-pl-14 lg:tw-text-white"
      >
        <p
          class="tw-mb-1 tw-text-center tw-text-base md:tw-text-lg lg:tw-text-left lg:tw-text-lg lg:tw-font-thin"
        >
          Ready to take your startup to the next level?
        </p>
        <p class="tw-text-xl tw-font-semibold tw-tracking-wide lg:tw-text-lg">Discover how.</p>
        <a
          class="tw-flex tw-w-full tw-cursor-pointer tw-justify-center tw-rounded-md tw-bg-[#88121E] tw-p-4 tw-text-base tw-font-semibold tw-text-white tw-shadow-[0px_4px_4px_0px_#00000040] hover:tw-border-none hover:tw-text-white hover:tw-outline-none lg:tw-mt-2 lg:tw-bg-white lg:tw-px-4 lg:tw-py-2 lg:tw-text-[#88121E] lg:hover:tw-bg-opacity-80 lg:hover:tw-text-[#88121E]"
          href="https://calendly.com/wtf-accelerator/45mins"
          target="_blank"
        >
          Book a session with us
        </a>
      </section>
    </div>
  </div>
</div>

<section class="section" *ngIf="totalCampaigns === null">
  <div class="container-fluid">
    <agmo-loading></agmo-loading>
  </div>
</section>

<!-- Section: Coming soon (when no campaign exist) -->
<section
  class="section tw-flex tw-min-h-96 tw-flex-col tw-items-center tw-justify-center"
  *ngIf="totalCampaigns !== null && totalCampaigns === 0"
>
  <h2 class="tw-text-sm tw-font-bold md:tw-text-lg">No available campaigns yet</h2>
  <div class="tw-text-sm md:tw-text-lg">All available campaigns will be shown here</div>
</section>

<!-- Section: Live -->
<section class="section" *ngIf="list[CampaignStatus.Live].total > 0">
  <div class="container-fluid">
    <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#88121E] md:tw-text-3xl">
      {{ 'common.liveOpportunities' | translate }}
    </h2>
    <app-campaign-list
      [campaigns]="list[CampaignStatus.Live].campaigns"
      [params]="list[CampaignStatus.Live].params"
      [total]="list[CampaignStatus.Live].total"
      [isLoading]="list[CampaignStatus.Live].isLoading"
      [noRecordText]="'common.comingSoon'"
      (getCampaignList)="getList($event, CampaignStatus.Live)"
    ></app-campaign-list>
  </div>
</section>

<!-- Section: Pre-Live -->
<ng-container *ngIf="list[CampaignStatus.PreLive].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#88121E] md:tw-text-3xl">
        {{ 'common.preLiveCampaigns' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.PreLive].campaigns"
        [params]="list[CampaignStatus.PreLive].params"
        [total]="list[CampaignStatus.PreLive].total"
        [isLoading]="list[CampaignStatus.PreLive].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.PreLive)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<!-- Section: Success -->
<ng-container *ngIf="list[CampaignStatus.Success].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#88121E] md:tw-text-3xl">
        {{ 'common.successCampaigns' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.Success].campaigns"
        [params]="list[CampaignStatus.Success].params"
        [total]="list[CampaignStatus.Success].total"
        [isLoading]="list[CampaignStatus.Success].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.Success)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<section
  class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-bg-[#88121E] tw-p-8 md:tw-gap-8 md:tw-p-16"
>
  <h3 class="tw-font-heading tw-text-xl tw-font-bold tw-tracking-wide tw-text-white md:tw-text-4xl">
    Discover Early Stage Startups
  </h3>
  <div class="tw-flex tw-max-w-[35rem] tw-flex-col md:tw-max-w-4xl">
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      The name WatchTower &amp; Friends (WTF) is inspired by Jimi Hendrix's song "All Along the
      WatchTower." A line from the song goes, "There's too much confusion, I can't get no relief."
      WTF aims to cut through the confusion that startups face with programs designed to rapidly
      elevate them to the next level.
    </p>
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      WTF's accelerator programs are based on the belief that connecting the companies they invest
      in with the right mentors, investors, and industry experts is the key to achieving scale and
      generating explosive growth.
    </p>
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      The co-founders of WTF Accelerator, Sam Shafie and Kashminder Singh, also lead Malaysia’s
      largest Equity Crowdfunding (ECF) platform, pitchIN. They believe in investing in and
      mentoring startup founders at the earliest stages.
    </p>
  </div>

  <a
    href="https://www.watchtowerfriends.com/"
    target="_blank"
    class="tw-rounded-full tw-bg-black tw-px-6 tw-py-4 tw-text-base tw-font-semibold tw-text-white hover:tw-bg-opacity-80 hover:tw-text-white md:tw-text-lg"
    >More on WatchTower &amp; Friends</a
  >
</section>
