import { Question } from './../../models/campaign/question';
import { CampaignInfo } from './../../models/campaign/campaign-info';
import { CampaignSectionFilter } from './../../../pages/landing/campaign-section/campaign-section-filter';
import { environment } from './../../../../environments/environment';
import { PagedResult } from './../../../shared/models/paged-result/paged-result';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { StorageService } from '../storage.service';
import { BusinessInfo } from '../../models/campaign/business-info';
import * as moment from 'moment';
import { CampaignNewsModel } from '../../models/campaign/campaign-news.model';
import { CampaignNews } from '../../models/campaign/campaign-news';
import { CampaignEventModel } from '../../models/campaign/campaign-event.model';
import { CampaignEvent } from '../../models/campaign/campaign-event';
import { InvestmentModel } from '../../models/investment/investment.model';
import { AccountService } from './account.service';
import { Payment } from '../../models/payment/payment';
import { PaymentModel } from '../../models/payment/payment.model';
import { Campaign } from '../../models/campaign/campaign';
import { MyInvestment } from '../../models/investment/my-investment';
import { Edd } from '../../models/investment/edd';
import { FinancialOverview } from '../../models/campaign/financial-overview';
import { StatisticCount } from '../../models/campaign/statistic-count';
import { Role } from '../../enum/role.enum';
import { CampaignWhatsNew } from '../../models/campaign/campaign-whats-new';
import { InvestmentFilterType } from '../../enum/investment-filter-type.enum';

@Injectable()
export class CampaignService {
  private route = environment.apiServer + 'Api/v1/ECF/{role}/campaigns';
  statisticCount: ReplaySubject<StatisticCount> = new ReplaySubject<StatisticCount>(1);

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private accountService: AccountService,
  ) {}

  getCampaigns(filter: CampaignSectionFilter): Observable<PagedResult<Campaign>> {
    const params: any = Object.assign({}, filter);

    if (params.orderBy && params.orderBy != 0) {
      params.orderBy = InvestmentFilterType[params.orderBy];
      params.IsOrderByDesc = true;
    } else delete params.orderBy;

    if (!params.filterString) delete params.filterString;

    // If `selectedSectors` is empty, don't override the `sectorIds`
    // as it might be from the url
    if (params.selectedSectors && params.selectedSectors.length > 0) {
      params.sectorIds = params.selectedSectors.map((sector) => sector.id || sector.key);

      delete params.selectedSectors;
    }

    if (
      !this.storageService.role ||
      ![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
    )
      params['noToken'] = 'noToken';

    return this.http.get(this.route, { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data ? data.result.data : [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  getCampaign(slug: string): Observable<Campaign> {
    const params =
      !this.storageService.role ||
      ![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
        ? { 'noToken': 'noToken' }
        : null;

    return this.http.get(this.route + '/' + slug, { params: params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getMyInvestment(slug: string): Observable<MyInvestment> {
    return this.http.get(this.route + '/' + slug + '/myinvestments').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getStatisticCount(campaignId: number): Observable<StatisticCount> {
    const params = {
      noToken: 'noToken',
    };

    return this.http
      .get(this.route + '/' + campaignId + '/statisticcount', { params: params })
      .pipe(
        map((data: any) => {
          return data.result;
        }),
      );
  }

  getCampaignWhatsNew(campaignId: number): Observable<CampaignWhatsNew> {
    const params = {
      noToken: 'noToken',
    };

    return this.http
      .get(environment.apiServer + 'Api/v1/ECF/public/campaigns/' + campaignId + '/whatsNew', {
        params: params,
      })
      .pipe(
        map((data: any) => {
          return data.result;
        }),
      );
  }

  getBusinessInfo(campaignId: number): Observable<BusinessInfo> {
    const params =
      !this.storageService.role ||
      ![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
        ? { 'noToken': 'noToken' }
        : null;

    return this.http.get(this.route + '/' + campaignId + '/businessInfo', { params: params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getCampaignInfo(campaignId: number): Observable<CampaignInfo> {
    const params =
      !this.storageService.role ||
      ![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
        ? { 'noToken': 'noToken' }
        : null;

    // use `switchMap` to handle both async calls
    // use `catchError` to handle incase one endpoint fails, the code will still returning the result
    return this.http.get(this.route + '/' + campaignId + '/info', { params: params }).pipe(
      catchError(() => of(null)),
      switchMap((data: any) => {
        return this.getCampaignWhatsNew(campaignId).pipe(
          catchError(() => of(null)),
          map((data2: any) => {
            data.result.campaignWhatsNew = data2;
            return data.result;
          }),
        );
      }),
    );
  }

  getCampaignNews(
    campaignId: number,
    currentPage: number,
    take: number,
    isPublished: boolean,
  ): Observable<PagedResult<CampaignNews>> {
    let params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
      isPublished: isPublished.toString(),
    };

    if (
      !this.storageService.role ||
      ![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
    )
      params['noToken'] = 'noToken';

    return this.http.get(this.route + '/' + campaignId + '/news', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data ? data.result.data : [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  getCampaignNewsUnreadCount(campaignId: number): Observable<number> {
    let params = {
      lastViewDateTime: this.storageService.lastViewDateTime || moment().toISOString(),
    };

    if (
      !this.storageService.role ||
      ![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
    )
      params['noToken'] = 'noToken';

    this.storageService.lastViewDateTime = moment().toISOString();

    return this.http
      .get(this.route + '/' + campaignId + '/news/unreadCount', { params: params })
      .pipe(
        map((data: any) => {
          return data.result ? data.result.unviewedCount : 0;
        }),
      );
  }

  getQuestions(
    campaignId: number,
    currentPage: number,
    take: number,
    questionId?: number,
    parentQuestionId?: number,
  ): Observable<PagedResult<Question>> {
    let params: any = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    if (questionId) params.QuestionId = questionId;

    if (parentQuestionId) params.parentQuestionId = parentQuestionId;

    return this.http.get(this.route + '/' + campaignId + '/questions', { params: params }).pipe(
      map((data: any) => {
        let result = data.result.data ? data.result.data : [];

        if (result.length > 0) {
          result.forEach((question) => {
            if (question.answers && question.answers.data && question.answers.data.length > 0) {
              question.answers = {
                data:
                  question.answers && question.answers.data.length > 0 ? question.answers.data : [],
                total: question.answers.totalCount,
              };
            }

            let subresult = question.answers && question.answers.data ? question.answers.data : [];

            if (subresult.length > 0) {
              subresult.forEach((answer) => {
                if (answer.answers && answer.answers.data && answer.answers.data.length > 0) {
                  answer.answers = {
                    data:
                      answer.answers && answer.answers.data.length > 0 ? answer.answers.data : [],
                    total: answer.answers.totalCount,
                  };
                }
              });
            }
          });
        }

        return {
          data: result,
          total: data.result.totalCount,
        };
      }),
    );
  }

  getAnswers(
    campaignId: number,
    questionId: number,
    skip: number,
    take: number,
  ): Observable<PagedResult<Question>> {
    const params = {
      skip: skip.toString(),
      take: take.toString(),
    };

    return this.http
      .get(this.route + '/' + campaignId + '/questions/' + questionId + '/answers', {
        params: params,
      })
      .pipe(
        map((data: any) => {
          let result = data.result.data ? data.result.data : [];

          if (result.length > 0) {
            result.forEach((subanswer) => {
              if (
                subanswer.answers &&
                subanswer.answers.data &&
                subanswer.answers.data.length > 0
              ) {
                subanswer.answers = {
                  data:
                    subanswer.answers && subanswer.answers.data.length > 0
                      ? subanswer.answers.data
                      : [],
                  total: subanswer.answers.totalCount,
                };
              }
            });
          }

          return {
            data: result,
            total: data.result.totalCount,
          };
        }),
      );
  }

  addQuestion(campaignId: number, text: string): Observable<any> {
    const body = {
      text: text,
    };

    return this.http.post(this.route + '/' + campaignId + '/questions', body);
  }

  addAnswer(campaignId: number, questionId: number, text: string): Observable<any> {
    const body = {
      text: text,
    };

    return this.http.post(
      this.route + '/' + campaignId + '/questions/' + questionId + '/answers',
      body,
    );
  }

  deleteQuestion(questionId: number, campaignId: number): Observable<any> {
    return this.http.delete(this.route + '/' + campaignId + '/questions/' + questionId);
  }

  deleteAnswer(answerId: number, questionId: number, campaignId: number): Observable<any> {
    return this.http.delete(
      this.route + '/' + campaignId + '/questions/' + questionId + '/answers/' + answerId,
    );
  }

  createCampaignNews(campaignId: number, news: CampaignNewsModel): Observable<any> {
    const body = news;

    return this.http.post(this.route + '/' + campaignId + '/news', body);
  }

  updateCampaignNews(
    campaignId: number,
    campaignNewsId: number,
    news: CampaignNewsModel,
  ): Observable<any> {
    const body = news;

    return this.http.put(this.route + '/' + campaignId + '/news/' + campaignNewsId, body);
  }

  deleteCampaignNews(campaignId: number, campaignNewsId: number): Observable<any> {
    return this.http.delete(this.route + '/' + campaignId + '/news/' + campaignNewsId);
  }

  getEvents(
    campaignId: number,
    currentPage: number,
    take: number,
  ): Observable<PagedResult<CampaignEventModel>> {
    const params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    if (
      !this.storageService.role ||
      ![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
    )
      params['noToken'] = 'noToken';

    return this.http.get(this.route + '/' + campaignId + '/events', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data ? data.result.data : [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  addEvent(event: CampaignEvent, campaignId: number): Observable<any> {
    let formData = new FormData();
    formData.append('title', event.title);
    formData.append('startDate', event.startDate);
    formData.append('endDate', event.endDate);
    formData.append('eventTags', JSON.stringify(event.eventTags));
    formData.append('eventLink', event.eventLink);
    formData.append(
      'AttachmentInfo.EncryptedAttachmentInfo',
      event.attachmentInfo.encryptedAttachmentInfo.toString(),
    );
    formData.append('AttachmentInfo.Token', event.attachmentInfo.token.toString());

    return this.http.post(this.route + '/' + campaignId + '/events', formData);
  }

  deleteEvent(campaignEventId: number, campaignId: number): Observable<any> {
    return this.http.delete(this.route + '/' + campaignId + '/events/' + campaignEventId);
  }

  udpateEvent(event: CampaignEventModel, campaignId: number, eventId: number): Observable<any> {
    let formData = new FormData();
    formData.append('title', event.title);
    formData.append('startDate', event.startDate);
    formData.append('endDate', event.endDate);
    formData.append('eventTags', JSON.stringify(event.eventTags));
    formData.append('eventLink', event.eventLink);
    formData.append(
      'AttachmentInfo.EncryptedAttachmentInfo',
      event.attachmentInfo ? event.attachmentInfo.encryptedAttachmentInfo.toString() : '',
    );
    formData.append(
      'AttachmentInfo.Token',
      event.attachmentInfo ? event.attachmentInfo.token.toString() : '',
    );
    return this.http.put(this.route + '/' + campaignId + '/events/' + eventId, formData);
  }

  getCampaignInvestmentInfo(campaignId: number): Observable<any> {
    return this.http.get(this.route + '/' + campaignId + '/invest/info').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getCampaignInvestorCount(campaignId: number): Observable<any> {
    const params =
      !this.storageService.role ||
      ![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
        ? { 'noToken': 'noToken' }
        : null;

    return this.http.get(this.route + '/' + campaignId + '/investorCount', { params: params }).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getCampaignInvestorList(
    campaignId: number,
    currentPage: number,
    take: number,
    isReport: boolean,
  ): Observable<PagedResult<InvestmentModel>> {
    let params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
      isGenerateReport: isReport.toString(),
    };

    if (
      !this.storageService.role ||
      ![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
    )
      params['noToken'] = 'noToken';

    return this.http.get(this.route + '/' + campaignId + '/investments', { params: params }).pipe(
      map((data: any) => {
        return {
          data: data.result.data ? data.result.data : [],
          total: data.result.totalCount,
        };
      }),
    );
  }

  invest(
    campaignId: number,
    lotAmount: number,
    referralCode: string,
    continueInvestment: boolean,
  ): Observable<any> {
    const body = {
      LotQuantity: lotAmount,
      ReferralCode: referralCode,
      ContinueInvestment: continueInvestment,
    };
    return this.http.post(this.route + '/' + campaignId + '/invest', body);
  }

  setInvestmentPayment(
    campaignId: number,
    investmentId: number,
    paymentModel: Payment,
  ): Observable<any> {
    const body = new PaymentModel(paymentModel);

    return this.http
      .post(this.route + '/' + campaignId + '/invest/' + investmentId + '/paymentType', body)
      .pipe(
        map((data: any) => {
          return data.result;
        }),
      );
  }

  getInvestment(campaignId: number, investmentId: number): Observable<InvestmentModel> {
    return this.http.get(this.route + '/' + campaignId + '/invest/' + investmentId).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  updateRssFeedUrl(campaignId: number, rssUrl: string): Observable<any> {
    const body = {
      RSSUrl: rssUrl,
    };

    return this.http.put(this.route + '/' + campaignId + '/rss', body).pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  getCampaignMyInvestments(slug: string): Observable<any> {
    return this.http.get(this.route + '/' + slug + '/myInvestments').pipe(
      map((data: any) => {
        return data.result;
      }),
    );
  }

  uploadSourceOfWealthDocument(
    slug: string,
    investmentId: number,
    investmentEddId: number,
    file: File,
  ): Observable<any> {
    const body = {
      FileName: file.name,
    };

    return this.http
      .post(
        this.route +
          '/' +
          slug +
          '/myInvestments/' +
          investmentId +
          '/edd/' +
          investmentEddId +
          '/attachment/sourceOfWealth',
        body,
      )
      .pipe(
        mergeMap((data: any) => {
          if (data && data.result) return this.accountService.uploadImage(data.result, file);
          else return data.result;
        }),
      );
  }

  uploadSourceOfFundsDocument(
    slug: string,
    investmentId: number,
    investmentEddId: number,
    file: File,
  ): Observable<any> {
    const body = {
      FileName: file.name,
    };

    return this.http
      .post(
        this.route +
          '/' +
          slug +
          '/myInvestments/' +
          investmentId +
          '/edd/' +
          investmentEddId +
          '/attachment/sourceOfFunds',
        body,
      )
      .pipe(
        mergeMap((data: any) => {
          if (data && data.result) return this.accountService.uploadImage(data.result, file);
          else return data.result;
        }),
      );
  }

  addEdd(slug: string, investmentId: number, investmentEddId: number, edd: Edd): Observable<any> {
    return this.http.post(
      this.route +
        '/' +
        slug +
        '/myInvestments/' +
        investmentId +
        '/edd/' +
        investmentEddId +
        '/form',
      edd,
    );
  }

  getEddForm(
    slug: string,
    investmentId: number,
    investmentEddId: number,
    investmentEddFormId: number,
  ): Observable<any> {
    return this.http
      .get(
        this.route +
          '/' +
          slug +
          '/myInvestments/' +
          investmentId +
          '/edd/' +
          investmentEddId +
          '/form/' +
          investmentEddFormId,
      )
      .pipe(
        map((data: any) => {
          return data.result;
        }),
      );
  }

  getFinancialOverview(
    campaignId: number,
    currentPage: number,
    take: number,
  ): Observable<PagedResult<FinancialOverview>> {
    let params = {
      currentPage: currentPage.toString(),
      take: take.toString(),
    };

    if (
      !this.storageService.role ||
      ![Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
    )
      params['noToken'] = 'noToken';

    return this.http
      .get(this.route + '/' + campaignId + '/financialoverview', { params: params })
      .pipe(
        map((data: any) => {
          return {
            data: data.result.data ? data.result.data : [],
            total: data.result.totalCount,
          };
        }),
      );
  }

  setCampaignViewCount(campaignId: number): Observable<any> {
    const params = {
      noToken: this.storageService.role ? null : 'noToken',
    };

    return this.http.post(this.route + '/' + campaignId + '/campaignView', null, {
      params: params,
    });
  }

  getCampaignDraft(campaignId: number): Observable<CampaignInfo> {
    return this.http.get(this.route + '/' + campaignId + '/campaignDetail/draft').pipe(
      map((data: any) => {
        data.result.campaignWhatsNew = this.getCampaignWhatsNew(data.result.campaignId);
        return data.result;
      }),
    );
  }

  submitCampaignDraft(campaignId: number, campaignInfo: CampaignInfo): Observable<any> {
    return this.http
      .put(this.route + '/' + campaignId + '/campaignDetail/draft', campaignInfo)
      .pipe(
        map((data: any) => {
          return data.result;
        }),
      );
  }

  deleteCampaignDraft(campaignId: number): Observable<any> {
    return this.http.delete(this.route + '/' + campaignId + '/campaignDetail/draft');
  }
}
