import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacySliderModule as MatSliderModule } from "@angular/material/legacy-slider";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TransferState } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { DigitOnlyModule } from "@uiowa/digit-only";
import { NgxMaskModule } from "ngx-mask";
import { MomentModule } from "ngx-moment";
import { DocumentsReportsComponent } from "../pages/landing/campaign-details/documents-reports/documents-reports.component";
import { CampaignCardComponent } from "../pages/landing/campaign-list/campaign-card/campaign-card.component";
import { CampaignListComponent } from "../pages/landing/campaign-list/campaign-list.component";
import { CampaignFiltersPortalComponent } from "../pages/landing/campaign-section/campaign-filters/campaign-filters-portal/campaign-filters-portal.component";
import { CampaignFiltersItemLabelComponent } from "../pages/landing/campaign-section/campaign-filters/campaign-filters-toggle/campaign-filters-item-label/campaign-filters-item-label.component";
import { CampaignFiltersToggleComponent } from "../pages/landing/campaign-section/campaign-filters/campaign-filters-toggle/campaign-filters-toggle.component";
import { MyOrdersComponent } from "../pages/mysec/my-orders/my-orders.component";
import { MysecSharesComponent } from "../pages/mysec/mysec-shares/mysec-shares.component";
import { ConfirmWithdrawComponent } from "../pages/mysec/wallet/confirm-withdraw/confirm-withdraw.component";
import { DepositComponent } from "../pages/mysec/wallet/deposit/deposit.component";
import { WalletComponent } from "../pages/mysec/wallet/wallet.component";
import { WithdrawComponent } from "../pages/mysec/wallet/withdraw/withdraw.component";
import { NotFoundPageComponent } from "../pages/not-found-page/not-found-page.component";
import { EditRepresentativeComponent } from "../pages/settings/edit-representative/edit-representative.component";
import { CompanyComponent } from "../pages/signup/company/company.component";
import { DocumentsComponent } from "../pages/signup/documents/documents.component";
import { InvestorProfileComponent } from "../pages/signup/investor-profile/investor-profile.component";
import { translateBrowserLoaderFactory } from "../shared/loader/translate-browser.loader.service";
import { SharedModule } from "../shared/shared.module";
import { BusinessesComponent } from "../pages/mysec/businesses/businesses.component";
import { BusinessesFiltersComponent } from "../pages/mysec/businesses/businesses-filters/businesses-filters.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    MomentModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSortModule,
    MatIconModule,
    MatSelectModule,
    MatSliderModule,
    MatDialogModule,
    MatTooltipModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    NgxMaskModule.forChild(),
    CKEditorModule,
    RouterModule,
    DigitOnlyModule,
    PortalModule,
  ],
  declarations: [
    CompanyComponent,
    DocumentsComponent,
    EditRepresentativeComponent,
    InvestorProfileComponent,
    CampaignListComponent,
    CampaignCardComponent,
    MysecSharesComponent,
    WalletComponent,
    MyOrdersComponent,
    DocumentsReportsComponent,
    NotFoundPageComponent,
    DepositComponent,
    WithdrawComponent,
    ConfirmWithdrawComponent,
    CampaignFiltersToggleComponent,
    CampaignFiltersPortalComponent,
    CampaignFiltersItemLabelComponent,
    BusinessesComponent,
    BusinessesFiltersComponent,
  ],
  exports: [
    CompanyComponent,
    DocumentsComponent,
    EditRepresentativeComponent,
    InvestorProfileComponent,
    CampaignListComponent,
    CampaignCardComponent,
    MysecSharesComponent,
    WalletComponent,
    MyOrdersComponent,
    DocumentsReportsComponent,
    NotFoundPageComponent,
    DepositComponent,
    WithdrawComponent,
    ConfirmWithdrawComponent,
    CampaignFiltersToggleComponent,
    CampaignFiltersPortalComponent,
    CampaignFiltersItemLabelComponent,
    BusinessesComponent,
    BusinessesFiltersComponent,
  ],
})
export class CoreModule {}
