import { Role } from './../../../core/enum/role.enum';
import { StorageService } from './../../../core/services/storage.service';
import { PopupService } from '../../../shared/services/popup/popup.service';
import { Attachment } from './../../../core/models/attachment/attachment';
import { CompanyService } from './../../../core/services/api/company.service';
import { ErrorService } from './../../../core/services/error.service';
import { AccountService } from './../../../core/services/api/account.service';
import { BlockUiService } from './../../../shared/services/blockUi/block-ui.service';
import { Representative } from './../../../core/models/user/representative';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CompanyModel } from '../../../core/models/company/company.model';
import { InvestorProfile } from '../../../core/models/user/investor-profile';
import { isPlatformBrowser } from '@angular/common';
import { CompanyLiteModel } from '../../../core/models/company/company-lite.model';

@Component({
  selector: 'app-register-corporate',
  templateUrl: './register-corporate.component.html',
  styleUrls: ['./register-corporate.component.scss'],
})
export class RegisterCorporateComponent implements OnInit {
  step: number = 1;
  attachments: Attachment[];

  company: CompanyLiteModel;

  Role: typeof Role = Role;

  constructor(
    private router: Router,
    private blockUiService: BlockUiService,
    private accountService: AccountService,
    private errorService: ErrorService,
    private companyService: CompanyService,
    private popupService: PopupService,
    private storageService: StorageService,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      this.checkSignUpFlow();
    }
  }

  checkSignUpFlow() {
    this.accountService
      .getSignUpFlowStatus()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (flowData) => {
          if (flowData) {
            if (!flowData.isCompleted) {
              this.step = flowData.completedSignUpFlowStage + 1;
              this.getCorporateBasicInfo();

              if (this.step == 4) this.getCompanyDocuments();
            } else this.router.navigate(['/']);
          } else {
            this.popupService.alert('common.userNotExists').then(() => {
              this.router.navigate(['/profile/settings/profile-details']);
            });
          }
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  getCorporateBasicInfo() {
    this.accountService.profile(true).subscribe((data) => {
      this.company = {
        name: data.companyName,
        registrationNumber: data.registrationNumber,
        companyId: data.companyId,
        brandName: data.brandName,
        companyType: data.companyType,
      };
    });
  }

  registerRep(event: Representative) {
    this.blockUiService.open();

    this.accountService
      .registerCompanyRep(event)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {
          this.step++;
          this.getCompanyDocuments();
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  submitCompany(company: CompanyModel) {
    this.blockUiService.open();

    this.accountService
      .registerCompanyInfo(company)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {
          this.step++;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  submitCorporateProfile(investorProfile: InvestorProfile) {
    this.blockUiService.open();

    this.accountService
      .registerCorporateProfile(investorProfile)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        () => {
          this.step++;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  getCompanyDocuments() {
    this.blockUiService.open();

    this.companyService
      .getCompanyAttachments()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (data) => {
          this.attachments = data;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  submit() {
    this.blockUiService.open();

    this.accountService
      .registerCompanyDocuments()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe(
        (data) => {
          if (!!data.result) {
            this.storageService.role = Role.CompanyRep;
            this.router.navigate(['/sign-up/proceed-e-kyc']);
          } else this.popupService.alert('company.pleaseUploadDocuments');
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }

  uploadCompanyDocument(document: Attachment) {
    this.companyService.uploadCompanyAttachment(document).subscribe(
      () => {
        this.getCompanyDocuments();
      },
      (err) => {
        this.errorService.showError(err);
        document.file = null;
      },
    );
  }

  deleteCompanyDocument(id: number) {
    this.companyService.deleteCompanyAttachment(id).subscribe(
      () => {
        this.getCompanyDocuments();
      },
      (err) => {
        this.errorService.showError(err);
      },
    );
  }

  uploadDocument(document: Attachment) {
    this.blockUiService.open();

    this.accountService.uploadDocument(document).subscribe(
      () => {
        this.getCompanyDocuments();
      },
      (err) => {
        this.blockUiService.close();
        this.errorService.showError(err);
        document.file = null;
      },
    );
  }
}
