<div class="tw-bg-black">
  <img
    src="./assets/img/hero/broku-pitchin.jpg"
    class="tw-mx-auto tw-block tw-w-full tw-max-w-[1280px]"
    alt="pitchIN x MRANTI"
  />
</div>

<section class="tw-bg-gray-100 tw-py-8">
  <div class="container">
    <div class="md:tw-grid md:tw-grid-cols-5 md:tw-items-center md:tw-gap-2">
      <div class="md:tw-col-span-2">
        <picture>
          <source
            media="(max-width: 767px)"
            srcset="./assets/img/hero/broku-pitchin-logos-horizontal.png"
          />
          <source media="(min-width: 768px)" srcset="./assets/img/hero/broku-pitchin-logos.png" />
          <img
            src="./assets/img/hero/broku-pitchin-logos.png"
            alt="BEYOND4 - pitchIN"
            class="tw-mx-auto tw-block tw-w-full sm:tw-max-w-[90%] lg:tw-max-w-[80%]"
          />
        </picture>
      </div>

      <p class="tw-mb-0 md:tw-col-span-3">
        Broku and pitchIN are proud to announce a new partnership aimed at empowering and
        accelerating early and mid-stage startups in Malaysia. Broku, Malaysia’s Branding and
        Marketing Strategist, will now be supporting its startups to raise funds via equity
        crowdfunding on pitchIN. This partnership will provide investors with a unique opportunity
        to support and invest in innovative and exciting startups in the region.
      </p>
    </div>
  </div>
</section>

<!-- Initial loading -->
<section class="section" *ngIf="totalCampaigns == null">
  <div class="container-fluid">
    <agmo-loading></agmo-loading>
  </div>
</section>

<!-- Section: Coming soon (when no campaign exist) -->
<section class="section" *ngIf="totalCampaigns != null && totalCampaigns == 0">
  <div class="container-fluid">
    <h2 class="font-weight-extrabold tw-mb-5">
      {{ 'common.investmentOpportunities' | translate }}
    </h2>
    <div class="tw-text-center">
      <div class="tw-my-10">{{ 'common.comingSoon' | translate }}</div>
    </div>
  </div>
</section>

<!-- Section: Live -->
<section class="section" *ngIf="list[CampaignStatus.Live].total > 0">
  <div class="container-fluid">
    <h2 class="font-weight-extrabold tw-mb-5">
      {{ 'common.liveOpportunities' | translate }}
    </h2>
    <app-campaign-list
      [campaigns]="list[CampaignStatus.Live].campaigns"
      [params]="list[CampaignStatus.Live].params"
      [total]="list[CampaignStatus.Live].total"
      [isLoading]="list[CampaignStatus.Live].isLoading"
      [noRecordText]="'common.comingSoon'"
      (getCampaignList)="getList($event, CampaignStatus.Live)"
    ></app-campaign-list>
  </div>
</section>

<!-- Section: Pre-Live -->
<ng-container *ngIf="list[CampaignStatus.PreLive].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5">
        {{ 'common.preLiveCampaigns' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.PreLive].campaigns"
        [params]="list[CampaignStatus.PreLive].params"
        [total]="list[CampaignStatus.PreLive].total"
        [isLoading]="list[CampaignStatus.PreLive].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.PreLive)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<!-- Section: Success -->
<ng-container *ngIf="list[CampaignStatus.Success].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5">
        {{ 'common.successCampaigns' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.Success].campaigns"
        [params]="list[CampaignStatus.Success].params"
        [total]="list[CampaignStatus.Success].total"
        [isLoading]="list[CampaignStatus.Success].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.Success)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<div class="section--partner tw-bg-[#231F20] tw-py-14 tw-text-white tw-text-opacity-80">
  <div class="container tw-max-w-3xl">
    <h3 class="tw-mb-4 tw-text-center tw-font-bold tw-text-[#FFEA06]">The BROKU Ecosystem</h3>

    <p>
      The Food & Beverage (F&B) sector has always been an integral part of an emerging economy. In
      Malaysia, F&B based Micro, Small and Medium Enterprise (MSME) contribute as much as 23.8% of
      national GDP in 2022, and it is expected to rise further in 2023, driven by increasing
      population, rising disposable income and thriving tourism industry.
    </p>

    <p>
      The F&B industry presents many opportunities for growth and success, but it also faces a
      multitude of challenges such as labour shortages, rising costs, increased competition and
      general lack of expertise in business management and marketing.
    </p>

    <p>
      BROKU was established as a response to the capacity gaps that exist with the creation of an
      comprehensive ecosystem with the purpose of nurturing and guiding entrepreneurs to increase
      sales and achieve success.
    </p>

    <p>
      With the aim of empowering entrepreneurs, BROKU provides strategic resources and key services
      such as specialised training programmes, mentoring, cutting-edge technology platform and
      financial expertise to drive growth and maximise business potentials.
    </p>

    <p>
      Equipped with a strong team with over 60 years combined experience in branding, marketing,
      hospitality management and financial , BROKU is well placed to provide solid, pragmatic and
      actionable strategies and solutions.
    </p>

    <div class="tw-mt-7 tw-text-center">
      <h5 class="tw-mb-3 tw-font-medium tw-text-white">Connect with us</h5>
      <a
        href="https://www.broku.com.my/"
        target="_blank"
        class="btn tw-rounded-full tw-bg-[#FFEA06] tw-text-[#231F20] hover:tw-text-[#231F20]"
      >
        More on BROKU
      </a>
    </div>
  </div>
</div>
