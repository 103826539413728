<div class="tw-bg-warning-light tw-py-10">
  <div class="container-fluid tw-text-center md:tw-flex md:tw-gap-3 md:tw-text-left">
    <div
      class="pitchin-icon pitchin-icon-warning_icon_red tw-inline-block tw-h-16 tw-w-16 tw-flex-shrink-0 tw-cursor-default"
    ></div>
    <div>
      <div class="tw-mb-1 tw-text-lg tw-font-bold tw-text-primary">
        {{ 'common.warning' | translate }}
      </div>
      <div>
        {{ 'landing.warningDesc' | translate }}
        <span
          class="font-weight-bold text-underline cursor-pointer"
          [routerLink]="['/risk-warning']"
        >
          {{ 'common.learnMore' | translate }}</span
        >
      </div>
    </div>
  </div>
</div>

<footer class="footer tw-text-white" [class.expand-footer]="isExpandFooter">
  <div class="container-fluid">
    <div class="tw-mb-5 tw-grid tw-gap-x-10 md:tw-grid-cols-3 lg:tw-grid-cols-4">
      <div class="footer__item tw-mb-10 md:tw-col-span-3 lg:tw-col-span-1 lg:tw-mb-5">
        <div
          class="pitchin-icon pitchin-icon-pitchin-logo-white tw-mb-3 tw-h-16 tw-w-20 tw-cursor-default"
        ></div>
        <p class="tw-mb-0 tw-text-base tw-leading-normal tw-text-white tw-text-opacity-50">
          {{ 'footer.companyDesc' | translate }}
        </p>
        <br />
        <a
          class="cursor-pointer tw-mb-0 tw-text-xs tw-leading-normal tw-text-white tw-text-opacity-50"
          [href]="'/assets/CHANGELOG.md'"
          [hidden]="'PRODUCTION' === environment.environment"
        >
          {{ 'v' + Version }}
        </a>
      </div>

      <div class="footer__item tw-mb-5" *ngFor="let footerItem of footerItems">
        <div class="footer__heading">
          {{ 'footer.' + footerItem.colTitle | translate }}
        </div>
        <ul class="footer__navlist">
          <ng-container *ngFor="let item of footerItem.items">
            <li
              class="footer__navlist-item tw-flex tw-flex-wrap tw-items-center tw-pb-2"
              *ngIf="!item.show || item.show()"
            >
              <span *ngIf="item.linkType == LinkType.File" (click)="popupFile(item.url)">
                {{ 'footer.' + item.title | translate }}
              </span>
              <a
                class="tw-mr-2"
                *ngIf="item.linkType == LinkType.URL"
                [href]="item.url"
                target="_blank"
              >
                {{ 'footer.' + item.title | translate }}
              </a>
              <a
                *ngIf="item.linkType == LinkType.RouterLink"
                [routerLink]="item.url"
                [fragment]="item?.fragment"
              >
                {{ 'footer.' + item.title | translate }}
              </a>
              <ng-container *ngIf="item.badge">
                <a
                  class="footer__badge"
                  *ngIf="item.badgeAction.linkType == LinkType.URL"
                  [href]="item.badgeAction.url"
                >
                  <i *ngIf="item.badgeIcon" class="tw-mr-0.5" [class]="item.badgeIcon"></i>
                  {{ 'footer.' + item.badge | translate }}
                </a>
              </ng-container>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>

    <div class="footer__notes">
      <p class="tw-mb-8 tw-text-center tw-text-sm tw-text-white tw-text-opacity-50 md:tw-text-left">
        <span class="tw-font-semibold">{{ 'footer.companyRegisteredNumber' | translate }}</span>
        <br />
        {{ 'footer.companyFullAddress' | translate }}
      </p>
      <div class="tw-text-center tw-text-white tw-text-opacity-20 md:tw-flex md:tw-items-center">
        <p class="tw-mb-5 tw-text-sm md:tw-mb-0">
          {{ 'common.copyright' | translate: { currentYear: currentYear } }}
        </p>

        <ul
          class="footer__socials tw-ml-auto tw-flex tw-justify-center tw-gap-x-4 md:tw-gap-x-2 md:tw-pl-5"
        >
          <!-- Facebook -->
          <li>
            <a
              href="https://www.facebook.com/pitchIN.my/"
              aria-label="pitchIN Facebook Page"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"
                />
              </svg>
            </a>
          </li>
          <!-- Twitter -->
          <li>
            <a
              href="https://twitter.com/pitchIN_my?s=20"
              aria-label="pitchIN Twitter"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                version="1.1"
                fill="currentColor"
              >
                <g id="surface1">
                  <path
                    d="M 2.8125 0 L 21.1875 0 C 22.742188 0 24 1.257812 24 2.8125 L 24 21.1875 C 24 22.742188 22.742188 24 21.1875 24 L 2.8125 24 C 1.257812 24 0 22.742188 0 21.1875 L 0 2.8125 C 0 1.257812 1.257812 0 2.8125 0 Z M 2.8125 0 "
                  />
                  <path
                    style="fill: #161d2d"
                    d="M 16.683594 4.6875 L 19.164062 4.6875 L 13.742188 10.882812 L 20.121094 19.3125 L 15.128906 19.3125 L 11.21875 14.199219 L 6.742188 19.3125 L 4.261719 19.3125 L 10.058594 12.6875 L 3.941406 4.6875 L 9.058594 4.6875 L 12.59375 9.359375 Z M 15.8125 17.828125 L 17.1875 17.828125 L 8.3125 6.09375 L 6.835938 6.09375 Z M 15.8125 17.828125 "
                  />
                </g>
              </svg>
            </a>
          </li>
          <li>
            <!-- Linkedin -->
            <a
              href="https://www.linkedin.com/company/pitchin"
              aria-label="pitchIN Linkedin"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
