<div
  class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-[url(src/assets/img/banner/mranti-mobile-bg.png)] tw-bg-cover tw-bg-no-repeat lg:tw-bg-[url(src/assets/img/banner/mranti-desktop-bg.png)] lg:tw-bg-[40%_30%]"
>
  <div class="lg:tw-flex lg:tw-w-full lg:tw-max-w-[1280px]">
    <div
      class="tw-flex tw-w-full tw-max-w-[1280px] tw-flex-col tw-items-center tw-gap-4 tw-px-16 tw-py-10 lg:tw-flex-row lg:tw-items-center lg:tw-justify-center lg:tw-gap-12 lg:tw-px-0 lg:tw-py-28"
    >
      <img
        src="./assets/img/hero/mranti-logo.png"
        alt="WatchTower and Friends"
        class="tw-w-80 lg:tw-w-[28rem]"
      />
      <h1
        class="tw-m-0 tw-text-center tw-font-heading tw-text-xl tw-font-bold tw-tracking-wide tw-text-white lg:tw-text-left lg:tw-text-6xl"
      >
        Ideas to Impact
      </h1>
    </div>
  </div>
  <div
    class="tw-relative tw-flex tw-w-screen tw-justify-center tw-border-white tw-shadow-[0px_4px_32px_0px_#00000029] lg:tw-border-t"
  >
    <div
      class="tw-absolute tw-left-0 tw-z-0 tw-hidden tw-h-full tw-w-6/12 tw-bg-white lg:tw-flex"
    ></div>
    <div
      class="tw-absolute tw-right-0 tw-z-0 tw-hidden tw-h-full tw-w-6/12 tw-bg-transparent tw-to-[99%] lg:tw-flex lg:tw-bg-transparent"
    ></div>
    <div
      class="tw-z-10 tw-flex tw-w-full tw-max-w-[1280px] tw-flex-col lg:tw-flex-row lg:tw-justify-center"
    >
      <section
        class="tw-w-full tw-p-1 lg:tw-flex lg:tw-flex-1 lg:tw-justify-end lg:tw-bg-white lg:tw-py-4"
      >
        <div class="tw-flex tw-flex-col tw-items-center tw-bg-white tw-p-8 lg:tw-pl-[15px]">
          <div class="tw-max-w-[35rem] tw-text-[#0000FF] md:tw-max-w-[80rem] lg:tw-pl-0">
            <p
              class="tw-text-left tw-text-base tw-font-semibold tw-leading-normal tw-tracking-wide sm:tw-text-sm md:tw-text-lg"
            >
              MRANTI and pitchIN are proud to announce a partnership aimed at empowering and
              accelerating early and mid-stage startups in Malaysia.
            </p>
            <p
              class="tw-text-left tw-text-base tw-font-normal tw-leading-normal sm:tw-text-sm md:tw-text-lg"
            >
              With a strategic focus on technology and innovation, MRANTI offers a comprehensive
              ecosystem that supports the entire innovation journey—from ideation to
              commercialization. MRANTI’s key advantage lies in the ability to fast-track the
              commercialization process, leveraging strong industry connections, funding
              opportunities, and expert guidance to turn groundbreaking ideas into impactful,
              market-ready solutions.
            </p>
          </div>
        </div>
      </section>
      <section
        class="tw-flex tw-flex-col tw-items-center tw-bg-white tw-p-6 tw-text-[#0000FF] lg:tw-max-w-[20rem] lg:tw-items-start lg:tw-justify-start lg:tw-bg-transparent lg:tw-py-12 lg:tw-pl-14 lg:tw-text-white"
      >
        <p
          class="tw-mb-1 tw-text-center tw-text-base md:tw-text-lg lg:tw-text-left lg:tw-text-lg lg:tw-font-thin"
        >
          Ready to transform your innovation into success?
        </p>
        <p class="tw-text-xl tw-font-semibold tw-tracking-wide lg:tw-text-lg">Discover how.</p>
        <a
          class="tw-flex tw-w-full tw-cursor-pointer tw-justify-center tw-rounded-md tw-bg-[#0000FF] tw-p-4 tw-text-base tw-font-semibold tw-text-white tw-shadow-[0px_4px_4px_0px_#00000040] hover:tw-border-none hover:tw-text-white hover:tw-outline-none lg:tw-mt-2 lg:tw-bg-white lg:tw-px-4 lg:tw-py-2 lg:tw-text-[#0000FF] lg:hover:tw-bg-opacity-80 lg:hover:tw-text-[#0000FF]"
          href="https://bit.ly/MRANTI-microsite"
          target="_blank"
        >
          Book a session with us
        </a>
      </section>
    </div>
  </div>
</div>

<section class="section" *ngIf="totalCampaigns === null">
  <div class="container-fluid">
    <agmo-loading></agmo-loading>
  </div>
</section>

<!-- Section: Coming soon (when no campaign exist) -->
<section
  class="section tw-flex tw-min-h-96 tw-flex-col tw-items-center tw-justify-center"
  *ngIf="totalCampaigns !== null && totalCampaigns === 0"
>
  <h2 class="tw-text-sm tw-font-bold md:tw-text-lg">No available campaigns yet</h2>
  <div class="tw-text-sm md:tw-text-lg">All available campaigns will be shown here</div>
</section>

<!-- Section: Live -->
<section class="section" *ngIf="list[CampaignStatus.Live].total > 0">
  <div class="container-fluid">
    <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#0000FF] md:tw-text-3xl">
      {{ 'common.liveOpportunities' | translate }}
    </h2>
    <app-campaign-list
      [campaigns]="list[CampaignStatus.Live].campaigns"
      [params]="list[CampaignStatus.Live].params"
      [total]="list[CampaignStatus.Live].total"
      [isLoading]="list[CampaignStatus.Live].isLoading"
      [noRecordText]="'common.comingSoon'"
      (getCampaignList)="getList($event, CampaignStatus.Live)"
    ></app-campaign-list>
  </div>
</section>

<!-- Section: Pre-Live -->
<ng-container *ngIf="list[CampaignStatus.PreLive].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#0000FF] md:tw-text-3xl">
        {{ 'common.preLiveCampaigns' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.PreLive].campaigns"
        [params]="list[CampaignStatus.PreLive].params"
        [total]="list[CampaignStatus.PreLive].total"
        [isLoading]="list[CampaignStatus.PreLive].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.PreLive)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<!-- Section: Success -->
<ng-container *ngIf="list[CampaignStatus.Success].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#0000FF] md:tw-text-3xl">
        {{ 'common.successCampaigns' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.Success].campaigns"
        [params]="list[CampaignStatus.Success].params"
        [total]="list[CampaignStatus.Success].total"
        [isLoading]="list[CampaignStatus.Success].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.Success)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<section
  class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-bg-[#0000FF] tw-p-8 md:tw-gap-8 md:tw-p-16"
>
  <h3 class="tw-font-heading tw-text-xl tw-font-bold tw-tracking-wide tw-text-white md:tw-text-4xl">
    About MRANTI
  </h3>
  <div class="tw-flex tw-max-w-[35rem] tw-flex-col md:tw-max-w-4xl">
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      The Malaysian Research Accelerator for Technology and Innovation (MRANTI), a convergence of
      Technology Park Malaysia (TPM) Corporation Sdn Bhd and the Malaysian Global Innovation and
      Creativity Centre (MaGIC), is Malaysia’s central research commercialisation agency that
      fast-tracks the development of technology innovations from ideas to impact.
    </p>
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      MRANTI serves as a connector, incubator and catalyst to enable early-stage ideation to mature
      entities to commercialise and scale. MRANTI offers innovators and industry access to
      world-class integrated infrastructure, interventions & programmes, partnership and a suite of
      resources. In doing so, MRANTI aims to expand Malaysia’s funnel of innovation supply, and
      unlock new R&D value by ensuring effective transitions in the commercialisation lifecycle. It
      will also link academia with industry and the public sector to streamline market-driven R&D
      efforts for mission-based outcomes
    </p>
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      MRANTI is headquartered at MRANTI Park, an extensive 686 acre 4IR innovation hub in Kuala
      Lumpur, is supporting the growth of smart manufacturing, biotech, agritech, smart city, green
      tech and enabling technology clusters.
    </p>
  </div>

  <a
    href="https://mranti.my/"
    target="_blank"
    class="tw-rounded-full tw-bg-black tw-px-6 tw-py-4 tw-text-base tw-font-semibold tw-text-white hover:tw-bg-opacity-80 hover:tw-text-white md:tw-text-lg"
    >More on MRANTI</a
  >
</section>
