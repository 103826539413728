<a [href]="tcfBaseUrl + '/campaign/' + campaign.id" class="tw-h-full">
  <div
    class="tw-flex tw-h-full tw-flex-col tw-gap-4 tw-rounded-lg tw-border tw-border-divider tw-bg-white !tw-p-0 tw-transition-shadow tw-duration-200 group-hover:tw-shadow-lg">
    <div class="tw-grid md:tw-grid-cols-2">
      <div
        class="tw-relative tw-z-0 tw-h-0 tw-w-full tw-overflow-hidden tw-rounded-lg tw-pb-[56.25%] md:tw-h-full md:tw-rounded-r-none md:tw-pb-0">
        <div class="tw-absolute tw-h-full tw-w-full">
          <img [src]="campaign.info?.coverImage ?? defaultBanner" [alt]="campaign.issuer.name"
            class="tw-h-full tw-w-full tw-object-cover tw-object-center" />
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-8 tw-p-6 max-sm:tw-pt-0">
        <div class="tw-relative tw-z-[2] tw-flex tw-flex-auto tw-flex-col max-md:-tw-top-8">
          <div class="tw-relative tw-flex tw-items-end tw-gap-5 max-md:tw-justify-between md:tw-items-center">
            <div class="tw-relative tw-h-20 tw-w-20 tw-shrink-0 tw-rounded tw-bg-white tw-shadow-lg max-md:tw-order-2">
              <img [src]="campaign.issuer.image ?? defaultLogo" alt="logo"
                class="tw-h-full tw-w-full tw-rounded tw-object-cover" />
            </div>
            <h2 class="tw-line-clamp-1 tw-text-lg tw-font-bold max-md:tw-order-1">
              {{ campaign.info.title }}
            </h2>
          </div>
          <div class="p-text-desc tw-py-2 tw-text-body-dark">{{ campaign.info.subtitle }}</div>
        </div>

        <div class="tw-flex tw-flex-col tw-text-body">
          <ng-container *ngIf="campaign.status !== 'PRELIVE'">
            <div *ngIf="campaign.status === 'LIVE'"
              class="tw-my-4 tw-inline-block tw-w-max tw-rounded tw-bg-secondary-lightest tw-px-2.5 tw-py-0.5 tw-text-sm tw-font-bold tw-text-secondary">
              {{ 'Live' | translate }}
            </div>
            <div *ngIf="campaign.status === 'COOLING_DOWN'"
              class="tw-my-4 tw-inline-block tw-w-max tw-rounded tw-bg-status-cooling/20 tw-px-2.5 tw-py-0.5 tw-text-sm tw-font-bold tw-text-status-cooling">
              {{ 'Cooling off' | translate }}
            </div>
            <div class="p-text-tag !tw-mb-0.5">{{ 'Raised' | translate }}</div>
            <div class="tw-mb-2.5 tw-flex tw-items-center">
              <div class="tw-mr-auto">
                <div class="tw-font-bold tw-text-black tw-text-opacity-80">
                  <span>{{
                    this.accumulatedInvestmentFiat | numberSuffix: { numberFormat: '1.0-0' }
                    }}
                    ({{ getPercentage() | number: '1.0-0' }}%)</span>
                </div>
              </div>
              <div class="text-opacity-80 tw-mr-2 tw-text-sm tw-text-body">
                <span *ngIf="campaign.status === 'LIVE' && calculateDayLeft(campaign.endDate) >= 2">
                  {{ campaign.endDate | amDifference: today : 'days' : true | number: '1.0-0' }}
                  {{ 'days left' | translate }}
                </span>
                <span *ngIf="campaign.status === 'LIVE' && calculateDayLeft(campaign.endDate) == 1">
                  {{ 'campaign.endTomorrow' | translate }}
                </span>
                <span *ngIf="
                    campaign.campaignStatus === 'LIVE' && calculateDayLeft(campaign.endDate) == 0
                  ">
                  {{ 'campaign.endToday' | translate }}
                </span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="campaign.status === 'PRELIVE'">
            <div
              class="tw-inline-block tw-w-max tw-rounded tw-bg-warning-light tw-px-2.5 tw-py-0.5 tw-text-sm tw-font-bold tw-text-warning">
              {{ 'Coming soon' | translate }}
            </div>
            <div class="tw-my-3.5 tw-h-px tw-w-full tw-bg-divider"></div>
          </ng-container>

          <div>
            <div *ngIf="campaign.status !== 'PRELIVE'" class="tw-mr-2">
              <div class="tw-h-2.5 tw-w-full tw-overflow-hidden tw-rounded-lg tw-bg-gray-200">
                <div [ngClass]="getPercentage() >= 100 ? 'tw-bg-ieo-green' : 'tw-bg-ieo'"
                  class="tw-h-2.5 tw-max-w-full tw-rounded-lg" [style.width.%]="getPercentage() || 0"></div>
              </div>
            </div>

            <div [class.tw-grid-cols-4]="campaign.status !== 'PRELIVE'"
              [class.tw-grid-cols-3]="campaign.status === 'PRELIVE'"
              class="tw-mt-2.5 tw-grid tw-divide-x tw-divide-gray-300">
              <div class="tw-pr-2">
                <label class="p-text-tag !tw-mb-0.5">{{ 'Max Tokens Offered' | translate }}</label>
                <div class="tw-text-sm tw-font-bold tw-text-black tw-text-opacity-80">
                  {{ campaign.offer.maxTokenOffered ?? campaign.token?.totalSupply | number }}
                  {{ campaign.token.symbol }}
                </div>
              </div>
              <div class="tw-px-2">
                <label class="p-text-tag !tw-mb-0.5">{{ 'Price per Token' | translate }}</label>
                <div class="tw-text-sm tw-font-bold tw-text-black tw-text-opacity-80">
                  {{
                  campaign.offer.pricePerToken / 100
                  | numberSuffix
                  : { numberFormat: addDecimalPlace(campaign.offer.pricePerToken / 100) }
                  }}
                </div>
              </div>
              <div [class.tw-px-2]="campaign.status !== 'PRELIVE'" [class.tw-pl-2]="campaign.status == 'PRELIVE'">
                <label class="p-text-tag !tw-mb-0.5">{{ 'Min Funding Target' | translate }}</label>
                <div class="tw-text-sm tw-font-bold tw-text-black tw-text-opacity-80">
                  {{ minFundingTarget | numberSuffix: { isSuffix: true } }}
                </div>
              </div>
              <div *ngIf="campaign.status !== 'PRELIVE'" class="tw-pl-2">
                <label class="p-text-tag !tw-mb-0.5">{{ 'Investors' | translate }}</label>
                <div class="tw-text-sm tw-font-bold tw-text-black tw-text-opacity-80">
                  {{ campaign.progress?.investorCount || 0 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>