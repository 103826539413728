<a
  class="tw-flex tw-h-64 tw-w-full tw-min-w-96 tw-flex-col tw-justify-between tw-p-4 tw-text-black tw-shadow-[0px_4px_4px_0px_#00000021]"
  [routerLink]="['/equity', campaign.slug]"
>
  <section class="tw-flex tw-items-start tw-justify-between">
    <div>
      <h3 class="tw-text-lg tw-font-bold">{{ campaign.company.brandName }}</h3>
      <p class="tw-text-black">{{ campaign.company.tagline }}</p>
      <app-tag-list
        [tags]="campaign.company?.sectors"
        [max]="2"
        [excludes]="['Shariah']"
      ></app-tag-list>
    </div>
    <img
      [src]="campaign.company.companyLogoUrl"
      [alt]="campaign.company.brandName"
      class="tw-h-20 tw-w-20 tw-rounded-md tw-border tw-border-black tw-object-cover tw-object-center"
    />
  </section>
  <div class="campaign-card__footer">
    <div class="tw-flex tw-flex-col">
      <!-- Raised amount -->
      <div *ngIf="campaign.campaignStatus !== CampaignStatus.PreLive">
        <div class="campaign-card__stat-label p-text-tag">
          {{ 'campaign.raised' | translate }}
        </div>
        <div class="tw-flex tw-items-center tw-gap-2">
          <div class="campaign-card__numbers tw-mr-auto tw-text-black">
            {{ campaign.bankedInAmount | numberSuffix }}
            ({{ calculateRaisedPercentange() }}%)
          </div>

          <!-- Show days left -->
          <div class="campaign-card__dayleft tw-whitespace-nowrap">
            <span
              *ngIf="
                campaign.campaignStatus === CampaignStatus.Live &&
                !campaign.isEnded &&
                calculateDayLeft(campaign.endAt) >= 2
              "
            >
              {{ campaign.endAt | amDifference: today : 'days' : true | number: '1.0-0' }}
              {{
                'campaign.daysLeft_plural'
                  | translate: { 'count': calculateDayLeft(campaign.endAt) }
              }}
            </span>
            <span *ngIf="campaign.campaignStatus === CampaignStatus.Live && campaign.isTomorrow">
              {{ 'campaign.endTomorrow' | translate }}
            </span>
            <span *ngIf="campaign.campaignStatus === CampaignStatus.Live && campaign.isToday">
              {{ 'campaign.endToday' | translate }}
            </span>
            <span
              class="tw-text-secondary-light"
              *ngIf="
                (campaign.campaignStatus !== CampaignStatus.Cooling && campaign.isEnded) ||
                campaign.campaignStatus === CampaignStatus.ShareAllotment
              "
            >
              {{ 'campaign.funded' | translate }}
            </span>
            <span *ngIf="campaign.campaignStatus === CampaignStatus.Fail">
              {{ 'campaign.Fail' | translate }}
            </span>
          </div>

          <!-- Cooling off label -->
          <div
            class="campaign-card__status tw-whitespace-nowrap tw-bg-info-light"
            *ngIf="campaign.campaignStatus === CampaignStatus.Cooling"
          >
            {{ 'campaign.' + CampaignStatus[campaign.campaignStatus] | translate }}
          </div>
        </div>
      </div>

      <div
        class="tw-w-fit tw-rounded tw-bg-warning-light tw-px-2"
        *ngIf="campaign.campaignStatus === CampaignStatus.PreLive"
      >
        {{ 'campaign.launchingSoon' | translate }}
      </div>

      <!-- Progress bar -->
      <ng-container
        *ngIf="
          campaign.campaignStatus === CampaignStatus.Live ||
            campaign.campaignStatus === CampaignStatus.Cooling;
          else showDivider
        "
      >
        <div class="tw-my-2.5">
          <mat-progress-bar
            aria-label="progressbar"
            mode="determinate"
            height="10"
            [color]="campaign.raisedPercentage > 100 ? 'success' : ''"
            [value]="calculateProgress(campaign.raisedPercentage)"
          >
          </mat-progress-bar>
        </div>
      </ng-container>

      <ng-template #showDivider>
        <div class="tw-my-3.5">
          <hr class="tw-my-0 tw-border-divider" />
        </div>
      </ng-template>

      <div
        class="campaign-card__stats tw-grid tw-divide-x tw-divide-y-0 tw-divide-solid tw-divide-divider"
        [class.tw-grid-cols-3]="campaign.campaignStatus !== CampaignStatus.PreLive"
        [class.tw-grid-cols-2]="campaign.campaignStatus === CampaignStatus.PreLive"
      >
        <!-- Valuation -->
        <div class="campaign-card__stat">
          <div class="campaign-card__stat-label p-text-tag tw-text-black">
            {{ 'campaign.valuation' | translate }}
          </div>
          <div class="campaign-card__numbers tw-text-sm tw-text-black">
            {{
              campaign.valuationAmount
                | numberSuffix
                  : {
                      isSuffix: true,
                      numberFormat: campaign.valuationAmount >= 1000000 ? '1.1-1' : '1.0-1',
                    }
            }}
          </div>
        </div>

        <!-- Target -->
        <div class="campaign-card__stat tw-pl-2">
          <div class="campaign-card__stat-label p-text-tag tw-text-black">
            {{ 'campaign.minTarget' | translate }}
          </div>
          <div class="campaign-card__numbers tw-text-sm tw-text-black">
            {{
              campaign.minimumTargetAmount
                | numberSuffix
                  : {
                      isSuffix: true,
                      numberFormat: campaign.minimumTargetAmount >= 1000000 ? '1.1-1' : '1.0-1',
                    }
            }}
          </div>
        </div>

        <!-- Investors -->
        <div
          class="campaign-card__stat tw-pl-2"
          *ngIf="campaign.campaignStatus !== CampaignStatus.PreLive"
        >
          <div class="campaign-card__stat-label p-text-tag tw-text-black">
            {{ 'campaign.investors' | translate }}
          </div>
          <div class="campaign-card__numbers tw-text-sm tw-text-black">
            {{ campaign.investorCount | number }}
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
