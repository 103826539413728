import { Component, OnInit } from '@angular/core';
import { CampaignStatus } from '../../../../core/enum/campaign-status.enum';

import { CampaignService } from '../../../../core/services/api/campaign.service';
import { CampaignSectionFilter } from '../../campaign-section/campaign-section-filter';
import { finalize } from 'rxjs';
import { ErrorService } from '../../../../core/services/error.service';
import { Campaign } from '../../../../core/models/campaign/campaign';

@Component({
  selector: 'app-women-in-tech',
  templateUrl: './women-in-tech.component.html',
})
export class WomenInTechComponent implements OnInit {
  list: {
    [key: number]: {
      campaigns: Campaign[];
      params: CampaignSectionFilter;
      total: number;
      isLoading: boolean;
    };
  } = {
    [CampaignStatus.Live]: {
      campaigns: [],
      params: null,
      total: 0,
      isLoading: true,
    },
    [CampaignStatus.PreLive]: {
      campaigns: [],
      params: null,
      total: 0,
      isLoading: true,
    },
    [CampaignStatus.Success]: {
      campaigns: [],
      params: null,
      total: 0,
      isLoading: true,
    },
  };

  totalCampaigns: number = null;

  CampaignStatus: typeof CampaignStatus = CampaignStatus;

  constructor(
    private campaignService: CampaignService,
    private errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    Object.keys(this.list).forEach((item) => {
      this.list[item].params = {
        ...new CampaignSectionFilter(),
        ...{
          take: 30,
          campaignStatus: CampaignStatus[item],
          sectorIds: 379, // SectorId for Women in Tech in production
        },
      };

      this.getList(this.list[item].params, item);
    });
  }

  getList(params: CampaignSectionFilter, campaignStatus: string | CampaignStatus) {
    const selectedCampaign = this.list[campaignStatus];

    selectedCampaign.campaigns = [];

    this.campaignService
      .getCampaigns(params)
      .pipe(finalize(() => (selectedCampaign.isLoading = false)))
      .subscribe(
        (data) => {
          selectedCampaign.campaigns = data.data;
          selectedCampaign.total = data.total;

          this.totalCampaigns = this.totalCampaigns + data.total;
        },
        (err) => {
          this.errorService.showError(err);
        },
      );
  }
}
