<div *ngIf="showFormError" class="error tw-mt-1.5 tw-px-5 tw-py-2 tw-text-xs tw-text-primary">
  <ng-container *ngIf="control?.errors?.['required'] || control?.errors?.['whitespace']">
    <ng-container *ngIf="inputName; else commonRequired">
      {{ 'common.fieldIsRequired' | translate: { field: inputName } }}
    </ng-container>
    <ng-template #commonRequired>
      {{ messageForRequired | translate }}
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control?.errors?.['pattern'] || control.errors?.['mask']">
    {{ messageForInvalidFormat | translate }}
  </ng-container>
  <ng-container *ngIf="control?.errors?.alreadyExists">
    {{ messageForInputExists | translate: { field: inputName } }}
  </ng-container>
  <ng-container *ngIf="control?.errors?.minlength">
    {{
      messageForMinLength
        | translate: { field: inputName, count: control?.errors?.minlength?.requiredLength }
    }}
  </ng-container>
  <ng-container *ngIf="control?.errors?.maxlength">
    {{
      messageForMaxLength
        | translate: { field: inputName, count: control?.errors?.maxlength?.requiredLength }
    }}
  </ng-container>
  <ng-container *ngIf="control?.errors?.notMatch">
    {{ messageForNotMatch | translate }}
  </ng-container>
  <ng-container *ngIf="control?.errors?.['matDatepickerMin']">
    {{ 'common.incorrectDate' | translate }}
  </ng-container>
  <ng-container *ngIf="control?.errors?.['phoneInvalidFormat']">
    {{ 'common.incorrectPhoneNoFormat' | translate }}
  </ng-container>
</div>
